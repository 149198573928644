export const WEBPAGE_TITLE = "1819 miðlar";
export const API_ENDPOINT = "https://api.torgid1819.com/api/";
export const API_1819_ENDPOINT = process.env.API_1819_URL
    ? process.env.API_1819_URL
    : "https://old.1819.is/api/";
export const API_NEW_1819_ENDPOINT = process.env.API_NEW_1819_URL
    ? process.env.API_NEW_1819_URL
    : "https://prod.1819.is/api/v1/";
export const APP_KEY = "3f8a97e6-51ea-44d7-a9c9-87ae6040f7fb";
export const GOOGLE_GA_MEASUREMENT_ID = "G-NYZBET9SFB";
export const GOOGLE_GA_TRACKING_ID = "UA-54712412-5";
export const PHONE_SYSTEM_API = "https://hysing178.bodleid.is:8889/";
export const THEME_COLOR = "#174d6f";
export const COLOR_RED = "#cf0000";
export const ISLAND_ID = process.env.ISLAND_ID ? process.env.ISLAND_ID : "1819.is";
export const LOGIN_PATH = process.env.LOGIN_PATH
    ? process.env.LOGIN_PATH
    : "https://login.signet.is/Login?id=1819.is";
export const MITT_MAX_CONTENT_WIDTH = 1150;
export const SMS_ACCESS_PRICE = 2000;
export const SMS_NOVA_PRICE = 6;
export const SMS_SEGMENT_PRICE = 8;

export const THJONUSTUVER_QUEUE_NUMBER = "8001";

export const MONTH_NAMES = [
    "janúar",
    "febrúar",
    "mars",
    "apríl",
    "maí",
    "júní",
    "júlí",
    "ágúst",
    "september",
    "október",
    "nóvember",
    "desember",
];

export const SOCIAL_MEDIA = [
    {
        name: "Facebook",
        key: "facebook",
        icon: "/img/social/facebook.svg",
        linkPrefix: "https://www.facebook.com/",
        prefixes: ["facebook.com/", "fb.com/", "fb.me/"],
    },
    {
        name: "X (Twitter)",
        key: "twitter",
        icon: "/img/social/twitter.svg",
        linkPrefix: "https://twitter.com/",
        prefixes: ["twitter.com/", "x.com/"],
    },
    {
        name: "Instagram",
        key: "instagram",
        icon: "/img/social/instagram.svg",
        linkPrefix: "https://www.instagram.com/",
        prefixes: ["instagram.com/"],
    },
    {
        name: "LinkedIn",
        key: "linkedin",
        icon: "/img/social/linkedin.svg",
        linkPrefix: "https://www.linkedin.com/",
        prefixes: ["linkedin.com/"],
    },
    // {
    //     name: "Snapchat",
    //     key: "snapchat",
    //     icon: "/img/social/snapchat.svg",
    //     linkPrefix: "https://www.snapchat.com/",
    // },
    // {
    //     name: "YouTube",
    //     key: "youtube",
    //     icon: "/img/social/youtube.svg",
    //     linkPrefix: "https://www.youtube.com/",
    // },
    // {
    //     name: "TikTok",
    //     key: "tiktok",
    //     icon: "/img/social/tiktok.svg",
    //     linkPrefix: "https://www.tiktok.com/@",
    // },
    // {
    //     name: "Tripadvisor",
    //     key: "tripadvisor",
    //     icon: "/img/social/tripadvisor.svg",
    //     linkPrefix: "https://www.tripadvisor.com/",
    // },
];

export const CALL_CAMPAIGN_DEFAULT_BUTTONS = [
    {
        title: "AÐAL - Tekur þátt",
        type: "positive",
        call_again_later: false,
        anyone_can_call_later: false,
        order: 1,
        draggableId: "1",
    },
    {
        title: "AÐAL - Hafa samband síðar",
        type: "neutral",
        call_again_later: true,
        anyone_can_call_later: false,
        order: 2,
        draggableId: "2",
    },
    {
        title: "Svaraði ekki",
        type: "neutral",
        call_again_later: true,
        anyone_can_call_later: true,
        order: 3,
        draggableId: "3",
    },
    {
        title: "Enginn áhugi",
        type: "negative",
        call_again_later: false,
        anyone_can_call_later: false,
        order: 4,
        draggableId: "4",
    },
    {
        title: "Búið að hringja 3x",
        type: "neutral",
        call_again_later: false,
        anyone_can_call_later: false,
        order: 5,
        draggableId: "5",
    },
];
