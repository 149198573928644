import React, { useState } from "react";
import getTranslatedString from "@helpers/GetTranslatedString";
import Collapse from "react-bootstrap/Collapse";
import Link from "next/link";
import { useRouter } from "next/router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp, faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import BorderedContainer from "@components/ui/bordered_container";
import { Image, Title } from "@components/ui";
import { FlexContainer } from "@components/ui/flex_container";
import classNames from "classnames";

export default function SideBar({ menu }) {
    if (!menu) {
        return <></>;
    }

    return (
        <>
            <nav
                className="desktop-menu-container"
                style={{ position: "sticky", top: 80, marginBottom: 20 }}
            >
                {menu.map((menuItem) => {
                    return (
                        <SideBarElement
                            key={menuItem?.title + menuItem?.link}
                            menuItem={menuItem}
                        />
                    );
                })}
            </nav>
        </>
    );
}

export function SideBarElement({ menuItem }) {
    if (!menuItem) {
        return <></>;
    }
    const [isOpen, setIsOpen] = useState(menuItem.default_open);
    const router = useRouter();

    const handleElementClick = (e) => {
        e.preventDefault();
        if (menuItem?.sub_pages?.length > 0) {
            e.stopPropagation();
            setIsOpen(!isOpen);
        } else {
            router.push(menuItem.link);
        }
    };
    const isSelected = router.pathname === menuItem.link;

    return (
        // <motion.div
        //     transition={{ duration: 0.3 }}
        //     initial={{ y: -15, opacity: 0 }}
        //     animate={{ y: 0, opacity: 1 }}
        // >
        <BorderedContainer>
            <a
                onClick={handleElementClick}
                className={classNames(
                    "d-flex align-items-center justify-content-between element-container",
                    { selected: isSelected }
                )}
                target={menuItem.isExternal ? "_blank" : ""}
            >
                <FlexContainer alignItems="center" style={{ color: "inherit" }}>
                    {menuItem.icon && <Image src={menuItem.icon} className="element-image" />}
                    <Title size={6} className="element-title">
                        {getTranslatedString({
                            is: menuItem.title,
                            en: menuItem.title_en,
                            pl: menuItem.title_pl,
                        })}
                    </Title>
                </FlexContainer>
                {menuItem?.sub_pages?.length !== undefined && (
                    <OpenClosedIndicator isOpen={isOpen} />
                )}
                {menuItem.isExternal && <StyledFontAwesomeIcon icon={faExternalLinkAlt} />}
            </a>
            <Collapse in={isOpen}>
                <div className="element-children-container">
                    {menuItem?.sub_pages?.map((subPage, index) => {
                        return (
                            <SideBarElementChild
                                menuItem={menuItem}
                                subPage={subPage}
                                index={index}
                                key={subPage?.title + subPage?.link}
                            />
                        );
                    })}
                </div>
            </Collapse>
        </BorderedContainer>
        // </motion.div>
    );
}

export function OpenClosedIndicator({ isOpen }) {
    return isOpen ? (
        <StyledFontAwesomeIcon icon={faChevronUp} />
    ) : (
        <StyledFontAwesomeIcon icon={faChevronDown} />
    );
}

export function StyledFontAwesomeIcon({ icon, style, className }) {
    return (
        <FontAwesomeIcon
            style={{
                height: 12,
                width: 12,
                color: "gray",
                alignSelf: "center",
                ...style,
            }}
            className={className}
            icon={icon}
        />
    );
}

function SideBarElementChild({ menuItem, subPage, index }) {
    if (menuItem === null || menuItem === undefined || subPage === null || subPage === undefined) {
        return <></>;
    }

    const router = useRouter();
    const isSelected = router.pathname === subPage?.link;

    return (
        <Link href={subPage?.link || ""} key={index}>
            <a target={subPage.isExternal ? "_blank" : ""}>
                <FlexContainer
                    className={classNames("element-child-container", {
                        selected: isSelected,
                    })}
                    justifyContent="between"
                    style={{
                        padding: "7px 15px",
                        borderBottomWidth: 1,
                        borderBottomStyle: index !== menuItem?.sub_pages.length - 1 && "dotted",
                    }}
                >
                    <div style={{ color: "inherit" }}>
                        {subPage.icon && <img src={subPage.icon} className="element-child-image" />}

                        {getTranslatedString({
                            is: subPage.title,
                            en: subPage.title_en,
                            pl: subPage.title_pl,
                        })}
                    </div>
                    {subPage.isExternal && <StyledFontAwesomeIcon icon={faExternalLinkAlt} />}
                </FlexContainer>
            </a>
        </Link>
    );
}
